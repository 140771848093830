.login-view {
  width: 100%;
  text-align: center;
  .center-float {
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    max-width: 400px;
    border: 2px solid #aaa;
    img {
      width: 200px;
    }
    .button-wrapper {
      padding: 20px;
    }
  }
}

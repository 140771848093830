.sort-table-footer {
  .dropdown-menu {
    top: -150%;
    .dropdown-item {
      cursor: pointer;
    }
  }
}
.table-responsive {
  .table td {
    padding: 0.4em 0.7em;
  }
}

.sidebar {
  .top-menu-item {
    display: block;
    padding: 1rem;
    width: 14rem;
    a {
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
    }
    font-size: 15px;
    .nav-click {
      width: 100%;
      display: block;
      i {
        margin-right: 4px;
        width: 18px;
        height: 18px;
      }
    }
    .menu-chev {
      position: relative;
      top: 5px;
      transition: all 0.3s;
      &.open {
        transform: rotate(180deg);
      }
    }
    & :hover {
      color: white;
    }
  }
  .sub-menu{
    background-color: white;
    border-radius: 10px;
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    .sub-menu-item {
      display: block;
      padding-left: 30px;
      color: black;
      font-size: 14px;
      padding: 8px 16px;
      text-decoration: none;
      border-radius: 6px;
      margin: 0 8px;
      &:hover{
        background-color: #eaecf4;
      }
    }
  }
  .active {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
    margin: 0 16px;
    padding: 16px 0;
  }
  
}

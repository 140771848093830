.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .profile {
        position: relative;
        cursor: pointer;
        > span:first-child {
            border-radius: 50%;
            border: 1px solid gray;
            width: 30px;
            height: 30px;
            display: inline-block;
            text-align: center;
            line-height: 30px;
        }
        .profile-full-name{
            font-weight: 600;
            font-size: 16px;
            color:  black;
        }
        &:hover .profile-sub-menu{
            opacity: 1;
            visibility: visible;
        }
        .profile-sub-menu{
            position: absolute;
            top: 35px;
            left: -30px;
            width: 150px;
            z-index: 100;
            border-radius: 5px;
            z-index: 100;
            font-weight: 400;
            color: #2e2f37;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s;
            ul {
                list-style-type: none;
                text-align: center;
            }
            li {
                border-radius: 5px;
                color: #7d7d83;
                &:hover{
                    color: black;
                }
            }
           
        }
    }
}